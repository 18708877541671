.CheckResultsCard {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
}

.CheckResultsCard > h1 {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.15px;
  margin-top: 0;
}

.CheckResultsCard > hr {
  margin: 24px 0;
}

.CheckResultsCard > div > li {
  margin: 16px 0;
  padding: 8px 16px;
}

.CheckResultsCard > div > li > div.ant-list-item-meta {
  align-items: center;
}

/* Error line */
.CheckResultsCard > div > li > .error-message > div > h4 {
  color: red;
  font-weight: 550;
  line-height: initial;
}

.CheckResultsCard > div > li > .error-message div,
.CheckResultsCard > div > li > .warning-message div {
  color: #2b8abd;
  font-size: small;
  line-height: 18px;
}

.CheckResultsCard > div > .error-category,
.CheckResultsCard > div > .warning-category {
  font-size: 22px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
}

.CheckResultsCard svg.close-icon-error,
.CheckResultsCard .error-report-icon {
  color: red;
}

.CheckResultsCard > div > .error-category > svg.hasNoErrors {
  color: green;
}

/* Warning line */
.CheckResultsCard > div > li > .warning-message > div > h4 {
  color: orange;
  font-weight: 550;
  line-height: initial;
  word-break: break-word;
}

.CheckResultsCard svg.close-icon-warning,
.CheckResultsCard .warning-report-icon {
  color: orange;
}

/* Success line*/
.CheckResultsCard > div > .warning-category > svg.hasNoErrors {
  color: green;
}

.CheckResultsCard > button {
  width: fit-content;
  align-self: flex-end;
}