.AdministrationModalContent {
  display: flex;
}

.AdministrationModalContent > .ModalLeftSection,
.AdministrationModalContent > .ModalRightSection {
  flex: 1;
}

.AdministrationModalContent > .ant-divider {
  height: auto;
}

/* MODAL LEFT SECTION */
.AdministrationModalContent > .ModalLeftSection .ant-spin-nested-loading > .ant-spin-container {
  display: flex;
  flex-direction: column;
  max-height: 60vh;
}

/* MODAL LEFT SECTION > NEW USER FORM */
.AdministrationModalContent > .ModalLeftSection .NewUserFormContainer > .NewUserFormHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AdministrationModalContent > .ModalLeftSection .NewUserFormContainer {
  padding: 0 16px;
}

.AdministrationModalContent > .ModalLeftSection .NewUserFormContainer > .NewUserForm > .ant-row {
  margin-bottom: 8px;
}

.AdministrationModalContent > .ModalLeftSection .NewUserFormContainer > .NewUserForm > .ant-row .ant-form-item {
  margin-bottom: 0;
}

.AdministrationModalContent > .ModalLeftSection .ant-divider,
.AdministrationModalContent > .ModalRightSection .ant-divider {
  margin: 8px 0;
}

/* MODAL LEFT SECTION > USERS LIST */
.AdministrationModalContent > .ModalLeftSection .ant-list {
  overflow-y: auto;
}

.AdministrationModalContent > .ModalLeftSection .ant-list .ant-list-item {
  cursor: pointer;
  padding: 8px 16px;
}

.AdministrationModalContent > .ModalLeftSection .ant-list .ant-list-item.selected,
.AdministrationModalContent > .ModalLeftSection .ant-list .ant-list-item:hover {
  background: #E2F1FF;
}

.AdministrationModalContent > .ModalLeftSection .ant-list .ant-list-item > button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

/* MODAL RIGHT SECTION */
.AdministrationModalContent > .ModalRightSection .CurrentUserInformation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.AdministrationModalContent > .ModalRightSection .ant-list {
  padding: 0 16px;
}

.ant-switch.ant-switch-checked {
  background-color: #00B4F8;
}
