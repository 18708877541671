.E404 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('~@assets/img/backgrounds/not-found.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
