.ForgotPasswordForm {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.ForgotPasswordForm > h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 56px;
  color: #073C4C;
}

.ForgotPasswordForm > p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 24px;
  color: #073C4C;
}

.ForgotPasswordForm > .ForgotPasswordFormField {
  margin-bottom: 24px;
}

.ForgotPasswordForm > .ForgotPasswordFormActions > button:first-of-type {
  margin-right: 8px;
}

.ForgotPasswordForm > .ForgotPasswordFormActions .LoadingSpinner svg {
  color: #FFFFFF;
}

.ForgotPasswordForm > .ForgotPasswordFormActions > .error-message,
.ForgotPasswordForm > .ForgotPasswordFormActions > .success-message {
  text-align: center;
  margin-bottom: 24px;
}
