.NewBotModal .template-type-cards-container {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
}

.NewBotModal .template-type-cards-container .bot-type-card>div>ul {
  padding-left: 32px;
}

.NewBotModal .template-type-cards-container .bot-type-card:not(:last-child) {
  margin-right: 24px;
}

.NewBotModal .template-type-cards-container>.template-type-card {
  font-size: 12px;
  margin: 0 8px;
}

.NewBotModal .template-type-cards-container h2 {
  color: #ffffff;
  font-size: 24px;
}

.NewBotModal .template-type-card .ant-card-head .ant-card-head-title {
  font-size: 20px;
  padding-bottom: 0;
}

.NewBotModal .template-type-card .ant-card-body {
  flex-direction: column;
  padding: 0px 16px 16px 16px;
}

.NewBotModal .template-type-card .ant-card-body {
  padding-top: 0;
}

.NewBotModal .template-type-card .ant-card-body ul {
  padding-left: 16px;
}

.NewBotModal .disabled {
  opacity: 0.5;
}

.NewBotModal .template-type-card .ant-card-body h3 {
  padding: 0 8px 8px 0px;
  color: rgba(0, 0, 0, 0.5);
}

.NewBotModal .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  background: #E2F1FF;
}
.NewBotModal .hint-with-icon {
  display: flex;
  align-items: center;
  gap: 4px;
}