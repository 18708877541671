svg.anticon.cdkicon {
  font-size: 24px;
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
  max-height: 90vh;
  overflow: auto;
  padding-top: 0;
	padding-bottom: 0;
  border-radius: 4px;
  scrollbar-width: thin;
  scrollbar-color: lightgrey lightgrey;
}

.ant-menu-submenu.ant-menu-submenu-horizontal > div.ant-menu-submenu-title > span {
  display: flex;
  align-items: center;
}

.ant-menu.ant-menu-sub.ant-menu-vertical  > li.ant-menu-item  {
  margin: 0;
  white-space: nowrap;
  line-height: 22px;
  height: auto;
  padding: 5px 12px;
}

.ant-menu.ant-menu-sub.ant-menu-vertical  > li.ant-menu-item span,
.ant-menu.ant-menu-sub.ant-menu-vertical  > li.ant-menu-item a {
  color: rgba(0, 0, 0, 0.65);
  height: fit-content;
  align-self: center;
  font-weight: 400;
  margin: -5px -12px;
  padding: 5px 12px;
}

.ant-menu.ant-menu-sub.ant-menu-vertical  > li.ant-menu-item:hover {
  background-color: #e6f7ff;
}

.ant-menu.ant-menu-sub.ant-menu-vertical  > li.ant-menu-item.selected {
  background-color: #073C4C;
}

.ant-menu.ant-menu-sub.ant-menu-vertical  > li.ant-menu-item.selected  span,
.ant-menu.ant-menu-sub.ant-menu-vertical  > li.ant-menu-item.selected a {
  color: #ffffff;
}

.ant-menu.ant-menu-sub.ant-menu-vertical  > li.ant-menu-item-disabled  {
  opacity: 1;
}

.ant-menu.ant-menu-sub.ant-menu-vertical  > li.ant-menu-item-disabled span,
.ant-menu.ant-menu-sub.ant-menu-vertical  > li.ant-menu-item-disabled a {
  color: rgba(0,0,0,.25);
}

.ant-menu.ant-menu-sub.ant-menu-vertical  > li.create.ant-menu-item.create {
  display: flex;
  align-items: center;
  background-color: #00B4F8;
  color: #FFFFFF;
  font-weight: bold;
}

.ant-menu.ant-menu-sub.ant-menu-vertical  > li.create.ant-menu-item.create > span,
.ant-menu.ant-menu-sub.ant-menu-vertical  > li.create.ant-menu-item.create > span.ant-menu-title-content > span {
  color: #FFFFFF;
}

.ant-menu.ant-menu-sub.ant-menu-vertical::-webkit-scrollbar {
  width: 8px;
}

.ant-menu.ant-menu-sub.ant-menu-vertical::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 20px;
  border: 3px solid lightgrey;
}

.ant-menu.ant-menu-sub.ant-menu-vertical::-webkit-scrollbar-track {
 background-color: #ffffff;
}


.administration-account-name {
 color: #00B4F8;
}

.ant-menu.ant-menu-horizontal.navbar-menu {
  line-height: 64px;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-menu.ant-menu-horizontal.navbar-menu > .ant-menu-item-only-child:hover,
.ant-menu.ant-menu-horizontal.navbar-menu > .navbar-menu-brand:hover {
  background-color: transparent;
}

.ant-menu.ant-menu-horizontal.navbar-menu > .navbar-menu-brand {
  margin-right: auto;
}

.ant-menu.ant-menu-horizontal.navbar-menu >.navbar-menu-brand img.navbar-menu-brand-icon {
  max-height: 34px;
}

.Navbar .ant-menu.navbar-menu .ant-menu-item.onboarding-item-menu svg {
  font-size: 25px;
  margin: auto;
  vertical-align: middle;
}

.Navbar .navbar-menu .ant-menu-item .anticon, .ant-menu-submenu-title i.anticon {
  min-width: initial;
  margin-right: initial;
}

/* Spaces between items */

.Navbar .navbar-menu {
  padding: 0 20px;
}

.Navbar .navbar-menu li.ant-menu-item,
.Navbar .navbar-menu li.ant-menu-submenu .ant-menu-submenu-title {
  padding: initial;
}

.Navbar ul.ant-menu.navbar-menu li:not(
  .Navbar .navbar-menu li.ant-menu-submenu.AdministrationSubMenu,
  .Navbar .navbar-menu li.navbar-menu-brand
) {
  margin-right: 20px;
}