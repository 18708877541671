/* ENVIRONMENTS CHECKBOXES */
.DeployForm > div.ant-form-item  {
  margin-bottom: 16px;
}

.DeployForm > div.ant-form-item .ant-checkbox-wrapper .ant-typography {
  color: inherit;
}

/* ERROR MESSAGES */
.DeployForm .warning-message,
.DeployForm .ant-form-item .ant-form-item-explain-error {
  color: red;
  font-style: italic;
}

.DeployForm .warning-message {
  text-align: center;
}
