.radio-card {
  flex: 1;
  border: 2px solid rgba(0, 0, 0, 0.26) !important;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
}

.radio-card > div > .radio-card-content > ul {
  color: rgba(0, 0, 0, 0.26);
}

.radio-card .ant-card-head {
  border: none;
  padding: 0 16px;
}

.radio-card .ant-card-head > .ant-card-extra > .ant-radio-wrapper{
  margin-right: 0;
}

.radio-card .ant-card-extra {
  padding: 8px 0;
}

.radio-card .ant-card-head .ant-card-head-title  {   
  font-size: 24px;
  word-break: break-all;
  white-space: normal;
}

.radio-card.selected {
  flex: 1;
  border: 2px solid var(--blue-capella) !important;
}

.radio-card.disabled {
  cursor: not-allowed;
}

.radio-card.selected > div > .radio-card-content > ul {
  color: #fff !important;
}