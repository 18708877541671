.App {
  height: 100%;
  color: #073C4C;
}

.App-Content {
  height: 100%;
}

.App-Content.App-Logged {
  padding-top: 64px;
}

.width-100 { width: 100% }
.mb-10 { margin-bottom: 10px }

.pointer {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.ellipsed {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.required,
.error-message {
  color: red;
}

.success-message {
  color: green;
}

.ant-tooltip {
  z-index: 1300;
}

.ant-tooltip-inner {
  width: auto !important;
}

.ant-dropdown {
  z-index: 1300;
}

.scrollable-content {
  max-height: calc(100vh - 65px);
  overflow-y: auto;
}

.align-center {
  display: flex;
  align-items: center;
}

.ant-layout-content {
  background-color: #FFFFFF;
  max-height: calc(100vh- 65px);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #073C4C;
  box-shadow: none;
}