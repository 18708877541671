.DeployConfirmation {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
}

.DeployConfirmation > h1 {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.15px;
  margin-top: 0;
}

.DeployConfirmation > hr {
  margin: 24px 0;
}

.DeployConfirmation > svg {
  align-self: center;
}

.DeployConfirmation > p {
  margin: 0;
  text-align: center;
}

.DeployConfirmation > .WarningIcon {
  color: #F29949;
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
}

.DeployConfirmation > .ImpactedEnv {
  color: red;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
}

.DeployConfirmation > .ActionsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.DeployConfirmation > .ActionsContainer > button {
  border-radius: 6px;
}

.DeployConfirmation b {
  color: red;
}
