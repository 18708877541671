.ResetPasswordForm {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.ResetPasswordForm > h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 72px;
  color: #073C4C;
}

.ResetPasswordForm > .ResetPasswordFormField {
  margin-bottom: 8px;
}

.ResetPasswordForm > .ResetPasswordFormActions {
  margin-top: 16px;
}

.ResetPasswordForm > .ResetPasswordFormActions .LoadingSpinner svg {
  color: #FFFFFF;
}

.ResetPasswordForm > .ResetPasswordFormActions > .error-message,
.ResetPasswordForm > .ResetPasswordFormActions > .success-message {
  text-align: center;
  margin-bottom: 24px;
}
