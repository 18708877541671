// @see https://github.com/ant-design/ant-design/issues/28939#issuecomment-900464475

body {
  .ant-menu-vertical.ant-menu-sub,
  .ant-menu-vertical-left.ant-menu-sub,
  .ant-menu-vertical-right.ant-menu-sub {
    max-height: calc(~'100vh - 100px');
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: calc(~'100% + 1px');
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    padding: 0 calc(~'50% - 16px / 2');
    text-overflow: clip;
  }

  .ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
    padding: 0 calc(~'50% - 16px / 2');
  }

  @media only screen and (max-width: 480px) {
    .ant-picker-calendar-header .ant-picker-calendar-month-select {
      width: calc(~'50% - 8px');
    }
  }

  .ant-drawer-header-no-title .ant-drawer-close {
    /* stylelint-disable-next-line function-calc-no-invalid */
    padding-right: calc(~'20px - var(--scroll-bar)');
  }

  .ant-modal {
    max-width: calc(~'100vw - 32px');
  }

  @media (max-width: 767px) {
    .ant-modal {
      max-width: calc(~'100vw - 16px');
    }
  }

  .ant-notification-notice {
    max-width: calc(~'100vw - 24px * 2');
  }

  .ant-notification-notice-message-single-line-auto-margin {
    width: calc(~'384px - 24px * 2 - 24px - 48px - 100%');
  }

  .ant-progress-show-info .ant-progress-outer {
    margin-right: calc(~'-2em - 8px');
    padding-right: calc(~'2em + 8px');
  }

  .ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
    margin-left: calc(~'-2em - 8px');
    padding-left: calc(~'2em + 8px');
  }

  .ant-steps-dot .ant-steps-item-tail::after,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    width: calc(~'100% - 20px');
  }

  .ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
    height: calc(~'100% - 24px');
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(~'100% - 18px - 2px');
  }

  .ant-switch-small.ant-switch-checked .ant-switch-handle {
    left: calc(~'100% - 12px - 2px');
  }

  .ant-switch-rtl.ant-switch-checked .ant-switch-handle {
    right: calc(~'100% - 18px - 2px');
  }

  .ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
    right: calc(~'100% - 12px - 2px');
  }

  .ant-table-filter-dropdown-submenu > ul {
    max-height: calc(~'100vh - 130px');
  }

  .ant-timeline-item-tail {
    height: calc(~'100% - 10px');
  }

  .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    left: calc(~'50% - 4px');
    width: calc(~'50% - 14px');
  }

  .ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
    width: calc(~'50% - 12px');
  }

  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
    left: calc(~'100% - 4px - 2px');
  }

  .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
    width: calc(~'100% - 18px');
  }

  .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
    height: calc(~'100% - 14px');
  }

  .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
    height: calc(~'100% - 15px');
  }

  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    width: calc(~'50% - 12px');
  }

  .ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
    left: calc(~'50% + 14px');
    width: calc(~'50% - 14px');
  }

  .ant-timeline-rtl.ant-timeline.ant-timeline-alternate
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline-rtl.ant-timeline.ant-timeline-right
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline-rtl.ant-timeline.ant-timeline-label
    .ant-timeline-item-left
    .ant-timeline-item-content {
    right: calc(~'50% - 4px');
  }

  .ant-timeline-rtl.ant-timeline.ant-timeline-label
    .ant-timeline-item-right
    .ant-timeline-item-label {
    right: calc(~'50% + 14px');
  }

  .ant-upload-list-picture .ant-upload-list-item-progress,
  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    width: calc(~'100% - 24px');
  }

  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    width: calc(~'100% - 14px');
  }
}

@primary-color: #00b4f8;@menu-dark-bg: #073c4c;@menu-dark-inline-submenu-bg: #073c4c;@layout-body-background: #ffffff;@layout-header-background: #073c4c;@layout-header-padding: 0;@menu-popup-bg: #FFFFFF;@link-color: #00b4f8;@error-color: #FF7D6E;@border-radius-base: 4px;