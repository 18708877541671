#SaveButton,
#DeployButton {
  display: flex;
  align-items: center;
  border-radius: 6px;
}

#SaveButton:has(svg) > span,
#DeployButton:has(svg) > span {
  margin-left: 4px;
}

#SaveButton.success,
#DeployButton.success {
  border-color: #6CCC86;
  background-color: #6CCC86;
}

#DeployButton.warning {
  border-color: orange;
  background-color: orange;
}

#SaveButton.error,
#DeployButton.error {
  border-color: #FF7D6E;
  background-color: #FF7D6E;
}

#SaveButton.disabled,
#DeployButton.disabled {
  background-color: #6A8A94;
  color: #073C4C;
  border-color: #6A8A94;
}

#SaveButton .anticon-save {
  font-size: 20px;
}