.NewPasswordForm {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.NewPasswordForm > h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #073C4C;
}

.NewPasswordForm> h5 {
  margin-bottom: 72px;
}

.NewPasswordForm > .NewPasswordFormField {
  margin-bottom: 8px;
}

.NewPasswordForm > .NewPasswordFormActions {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}
.NewPasswordForm > .NewPasswordFormActions > button {
  margin: 4px;
}

.NewPasswordForm > .NewPasswordFormActions > .error-message {
  text-align: center;
  margin-bottom: 24px;
}
