div.ant-modal.DeployPopover  {
  left: 40%;
  top: 64px !important;
}

div.ant-modal.DeployPopover > div.ant-modal-content {
  border-radius: 4px;
}

div.ant-modal.DeployPopover > div.ant-modal-content > .ant-modal-body .ActionsContainer {
  display: flex;
  justify-content: flex-end;
}

div.ant-modal.DeployPopover > div.ant-modal-content > .ant-modal-body .ActionsContainer:has(.ant-btn-danger) {
  justify-content: space-between;
}

div.ant-modal.DeployPopover > div.ant-modal-content > .ant-modal-body .ActionsContainer > #DeployFormSubmitButton {
  display: flex;
  align-items: center;
}

div.ant-modal.DeployPopover > div.ant-modal-content > .ant-modal-body > .ant-divider {
  margin: 8px 0 16px 0;
}
