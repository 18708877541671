.LoginForm {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.LoginForm > h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 48px;
  margin-bottom: 56px;
  color: #073C4C;
}

.LoginForm > .LoginFormField {
  margin-bottom: 8px;
}

.LoginForm > .LoginFormActions {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 24px;
}

.LoginForm > .SubmitButtonContainer .LoadingSpinner svg {
  color: #FFFFFF;
}

.LoginForm > .SubmitButtonContainer .error-message {
  text-align: center;
  margin-bottom: 24px;
}
