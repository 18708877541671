.LayoutContainer {
  width: 100%;
  height: 100%;
  display: flex;
  color: #073C4C;
}

.LayoutContainer > .LayoutLeftSection,
.LayoutContainer > .LayoutRightSection {
  flex: 1;
}

.LayoutContainer > .LayoutLeftSection {
  padding: 16px 32px;
}

.LayoutContainer > .LayoutLeftSection > .Logo > img {
  height: 36px;
  cursor: pointer;
}

.LayoutContainer > .LayoutLeftSection > .LayoutContent {
  height: calc(100% - 36px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.LayoutContainer > .LayoutRightSection {
  background-image: url('~@assets/img/backgrounds/login-background.svg');
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
}