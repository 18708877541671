
:root {
    --red-carraya: #FF7D6E;
    --blue-capella: #00B4F8;
}

.DeleteBotModal div, .DeleteBotModal p {
    font-weight: bold;
    color: #073C4C;
}

.DeleteBotModal .ant-modal-content .ant-modal-header .bot-name-header{
    color: var(--blue-capella);
}

.DeleteBotModal .ant-checkbox-wrapper {
    margin-bottom: 1em;
}
.DeleteBotModal .ant-modal-body .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--blue-capella);
    border-color: var(--blue-capella);
}

.DeleteBotModal .ant-modal-body .bot-name-checkbox{
    color: var(--red-carraya);
}

.DeleteBotModal .ant-modal-footer > div > button:last-child{
    background-color: var(--red-carraya);
    border-color: var(--red-carraya);
}