div.ant-modal.DeployPopover > div.ant-modal-content > .ant-modal-body > .ant-collapse .ant-collapse-header,
div.ant-modal.DeployPopover > div.ant-modal-content > .ant-modal-body > .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

div.ant-modal.DeployPopover > div.ant-modal-content > .ant-modal-body > .ant-collapse .ant-collapse-content > .ant-collapse-content-box .ant-list-item > .ant-typography {
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

div.ant-modal.DeployPopover > div.ant-modal-content > .ant-modal-body > .ant-collapse .ant-collapse-content > .ant-collapse-content-box .ant-list-item > .ant-typography > button {
  margin: 0;
  display: flex;
  align-items: center;
  color: #00b4f8;
}