.Home {
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('~@assets/img/backgrounds/home-background.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Home > .HomeHint {
  padding-bottom: 20vh;
  white-space: pre-line;
}

.Home > .HomeHint > h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 6.95vh;
  line-height: 97.9%;
  margin-bottom: 16px;
  text-align: center;
}

.Home > .HomeHint > p {
  font-style: normal;
  font-size: 2.6vh;
  line-height: 2.7vh;
  text-align: center;
  letter-spacing: 0.03vh;
  color: #6A8A94;
}
